export const useSchedule = () => {
  const { locale } = useI18n();
  const { goto } = useUtils();

  const showSchedulePopup = () => {
    goto(
      `https://app.lemcal.com/@zweikern/free-call-${locale.value === "de" ? "de" : "en"}`,
    );
    return false;
    // Calendly.showPopupWidget(
    //   `https://calendly.com/d/${locale.value === 'de' ? 'ngzy-rz4x/kostenloses-erstgesprach' : 'nj4f-ckdn/free-initial-consultation'}`);
  };

  const showAnalyticsDemoPopup = () => {
    goto(
      `https://app.lemcal.com/@zweikern/analytics-demo-${locale.value === "de" ? "de" : "en"}`,
    );
    return false;
    // Calendly.showPopupWidget(
    //   `https://calendly.com/d/${locale.value === 'de' ? 'ngzy-rz4x/kostenloses-erstgesprach' : 'nj4f-ckdn/free-initial-consultation'}`);
  };

  return {
    showSchedulePopup,
    showAnalyticsDemoPopup,
  };
};
